import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c726ec5"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, {
    class: "product-card ion-text-center product-card-title",
    color: $props.isFeatured ? 'primary' : '',
    "router-link": {
    name: 'ProductDetailPage',
    params: { id: $props.product.id, ...$props.extraRouterParams }
  },
    button: ""
  }, {
    default: _withCtx(() => [
      ($props.product.sellingPrice < $props.product.price)
        ? (_openBlock(), _createBlock(_component_ion_badge, {
            key: 0,
            class: "sale-badge",
            color: "danger"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.t('sale')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!$props.product.relatedProductVariants && !$props.product.inStock)
        ? (_openBlock(), _createBlock(_component_ion_badge, {
            key: 1,
            class: "sold-out-badge",
            color: "medium"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.t('soldOut')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      ($props.isFeatured)
        ? (_openBlock(), _createElementBlock("img", {
            key: 2,
            class: "featured-product-img img",
            src: $props.product.imageLink
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("img", {
            key: 3,
            class: "product-img img",
            src: $props.product.imageLink
          }, null, 8, _hoisted_2)),
      _createVNode(_component_ion_card_header, { class: "product-header" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_card_subtitle, {
            color: "dark",
            class: "ion-text-center ion-text-nowrap"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.product.title), 1)
            ]),
            _: 1
          }),
          (!$props.hideMerchantName)
            ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                key: 0,
                class: "ion-text-center subtitle"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($props.product.merchantName), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($props.product.sellingPrice != $props.product.price)
            ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                key: 1,
                class: "ion-text-center special-offer"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("s", null, "HK$" + _toDisplayString(Number($props.product.price).toLocaleString()), 1),
                  _createTextVNode(" HK$" + _toDisplayString(Number($props.product.sellingPrice).toLocaleString()), 1)
                ]),
                _: 1
              }))
            : (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                key: 2,
                class: "ion-text-center price"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" HK$" + _toDisplayString(Number($props.product.sellingPrice).toLocaleString()), 1)
                ]),
                _: 1
              }))
        ]),
        _: 1
      }),
      ($props.showActionButtons)
        ? (_openBlock(), _createBlock(_component_ion_buttons, {
            key: 4,
            class: "product-actions"
          }, {
            default: _withCtx(() => [
              ($props.product.inStock)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.openCartItemModal($props.product)), ["stop"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        icon: $setup.cartOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              ($props.userLoggedIn && $props.product.likedByUser)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 1,
                    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.updateUserLikedItem($props.product, 'remove')), ["stop"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        icon: $setup.heart,
                        color: "danger"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              ($props.userLoggedIn && !$props.product.likedByUser)
                ? (_openBlock(), _createBlock(_component_ion_button, {
                    key: 2,
                    onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.updateUserLikedItem($props.product, 'add')), ["stop"]))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        icon: $setup.heartOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["color", "router-link"]))
}