
// icons
import { heart, heartOutline, cartOutline } from "ionicons/icons";

// components
import { IonBadge, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
          IonChip, IonLabel, IonIcon, IonButtons, IonButton, loadingController, modalController, } from "@ionic/vue";

// composables
import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';

import ProductService from '@/services/ProductService';
import CartItemModal from '@/components/templates/CartItemModal.vue';

export default {
  props: [
    "product",
    "hideDetails",
    "hideMerchantName",
    "extraRouterParams",
    "userLoggedIn",
    "isFeatured",
    "showActionButtons",
  ],
  components: {
    IonBadge, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
    IonChip, IonLabel, IonIcon, IonButtons, IonButton,
  },
  setup() {
    const { t } = useI18n();
    const { presentToast } = utils();

    const openCartItemModal = async (product: any) => {
      const modal = await modalController.create({
        component: CartItemModal,
        componentProps: { productId: product.id },
      });
      return modal.present();
    };
    const updateUserLikedItem = async (product: any, action = 'add') => {
      const loading = await loadingController.create({});
      await loading.present();
      if (action == 'add') await ProductService.addUserLikedItem(product.id);
      else await ProductService.removeUserLikedItem(product.id);
      product.likedByUser = (action == 'add');
      loading.dismiss();
      presentToast( t('successUpdateLikedItems'), 2000, 'top' );
    }

    return {
      // icons
      cartOutline, heart, heartOutline,

      // methods
      t, openCartItemModal, updateUserLikedItem,
    };
  },
};
