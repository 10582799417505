
// Vue reactivity
import { ref, defineComponent, reactive, computed, watchEffect, } from 'vue';

// icons
import { close, add, remove, cartOutline, homeOutline, storefrontOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
        IonIcon, IonButtons, IonButton, IonInput, IonTextarea, IonRange,
        IonList, IonListHeader, IonItem, IonLabel, IonSegment, IonSegmentButton,
        IonSelect, IonSelectOption, IonCard, IonCardHeader, IonCardSubtitle, IonCheckbox,
        modalController, loadingController, } from '@ionic/vue';

// composables
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'CartItemModal',
  props: ["productId", "editingCartItem"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter,
                IonIcon, IonButtons, IonButton, IonInput, IonTextarea, IonRange,
                IonList, IonListHeader, IonItem, IonLabel, IonSegment, IonSegmentButton,
                IonSelect, IonSelectOption, IonCard, IonCardHeader, IonCardSubtitle, IonCheckbox,
              },
  setup(props) {
    const store = useStore();
    const { presentToast, closeModal } = utils();
    const { t } = useI18n();
    const router = useRouter();
    
    // 1. declare state variables (ref to make them reactive)
    const product = computed(() => store.getters.getProductById(props.productId));
    const productQuestions = computed(() => store.getters.getQuestionsByProductId(props.productId));
    const merchant = computed(() => store.getters.getMerchantById(product.value.merchantId));
    const cartItem = reactive({
      id: null,
      quantity: 1,
      checkedCbxQuestions: [],
      questionAnswers: {}, // store answer of eaach question
      flag: 0, // for triggering line total update only
    });
    const isEditingCartItem = ref(false);
    
    // editing cart item
    if (props.editingCartItem) {
      watchEffect(() => {
        if (isEditingCartItem.value == false) { // init once only
          const { id, quantity, answers, checkedCbxQuestions, questionAnswers, productQuestions: savedQuestions } = props.editingCartItem;
          cartItem.id = id;
          cartItem.quantity = quantity;
          cartItem.checkedCbxQuestions = checkedCbxQuestions;
          cartItem.questionAnswers = questionAnswers;
          isEditingCartItem.value = true;
        }
      })
    }

    // methods or filters
    const decrementCartItemQty = () => {
      if (cartItem.quantity > 1) cartItem.quantity--;
    }
    const incrementCartItemQty = () => {
      cartItem.quantity++;
    }
    const onFinishInputCartItemQty = (e: any) => {
      const inputQty = e.target.value;
      cartItem.quantity = inputQty > 0 ? Math.ceil(+inputQty) : +cartItem.quantity + 1;
    }

    const getCartItemUnitPrice = () => {
      const { checkedCbxQuestions, questionAnswers, flag } = cartItem;
      
      // checkbox question (checked)
      const optionTotal = checkedCbxQuestions.reduce((total: any, o: any) => total += Number(o.price), 0);

      // single / multi-select (selected option sum the price)
      const answerTotal = Object.entries(questionAnswers).reduce((total: any, entry: any) => {
        const [questionId, val] = entry;
        const relatedQuestion = productQuestions.value.find(q => q.id == questionId);
        if (relatedQuestion) {
          if (relatedQuestion.type == 'quantity-input') {
            return total += Number(relatedQuestion.price * (val || 0)); // val = input qty
          }
          const price = Array.isArray(val) ? val.reduce((total: any, o) => (total += Number(o.price)), 0) : val.price;
          return total += Number(price);
        }
        return total;
      }, 0);

      return Number(product.value.sellingPrice) + optionTotal + answerTotal;
    }

    const addProductToCart = async (product: any, cartItem: any) => {
      const loading = await loadingController.create({});
      await loading.present();

      cartItem.isCustomized = productQuestions.value.length > 0; // true if customized products
      cartItem.unitPrice = getCartItemUnitPrice(); // include product question answers

      store.dispatch('addProductToCart', { product, cartItem, productQuestions: productQuestions.value });
      loading.dismiss();
      if (isEditingCartItem.value == true) {
        presentToast( t('successUpdatedCart'), 2000, 'top');
      } else {
        presentToast( t('successAddedToCart'), 3000, 'top', [{
          text: t('goToCart'),
          role: 'cancel',
          handler: () => router.push('/cart'),
        }]);
      }
      closeModal();
    }

    const answeredAllRequiredQuestions = (productQuestions) => {
      let answeredAllRequired = true;
      for (const q of productQuestions) {
        if (q.isRequired) {
          if ((q.type == 'quantity-input' && q.id in cartItem.questionAnswers) ||
              (q.type.includes('select') && q.id in cartItem.questionAnswers) ||
              (q.type == 'checkbox' && cartItem.checkedCbxQuestions.find(question => question.id == q.id))) {
            continue;
          } else {
            answeredAllRequired = false;
            break;
          }
        }
      }
      return answeredAllRequired;
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      close, add, remove, cartOutline, homeOutline, storefrontOutline,

      // variables
      cartItem, isEditingCartItem,
      product, productQuestions, merchant,

      // methods
      t, closeModal,
      incrementCartItemQty, decrementCartItemQty, onFinishInputCartItemQty,
      addProductToCart, getCartItemUnitPrice,
      answeredAllRequiredQuestions,
    }
  }
});
