import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4af782ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "ion-text-center" }
const _hoisted_3 = ["value"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["onUpdate:modelValue", "min", "max"]
const _hoisted_9 = { key: 2 }
const _hoisted_10 = ["value"]
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  class: "ion-text-center",
  style: {"padding-top":"5px","padding-bottom":"5px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t('orderForm')), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  slot: "icon-only",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { name: "close" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { fullscreen: true }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_card, { class: "ion-text-center product-card product-card-title" }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              class: "product-img",
              src: _ctx.product.imageLink
            }, null, 8, _hoisted_1),
            _createVNode(_component_ion_card_header, { class: "product-header" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_card_subtitle, {
                  color: "dark",
                  class: "ion-text-center"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.product.title), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_card_subtitle, { class: "ion-text-center" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.product.merchantName), 1)
                  ]),
                  _: 1
                }),
                (_ctx.product.sellingPrice != _ctx.product.price)
                  ? (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                      key: 0,
                      class: "ion-text-center"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("s", null, "HK$" + _toDisplayString(Number(_ctx.product.price).toLocaleString()), 1),
                        _createTextVNode(" HK$" + _toDisplayString(Number(_ctx.product.sellingPrice).toLocaleString()), 1)
                      ]),
                      _: 1
                    }))
                  : (_openBlock(), _createBlock(_component_ion_card_subtitle, {
                      key: 1,
                      class: "ion-text-center"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" HK$" + _toDisplayString(Number(_ctx.product.sellingPrice).toLocaleString()), 1)
                      ]),
                      _: 1
                    }))
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_ion_button, {
            class: "qty-action-btn",
            onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.decrementCartItemQty()), ["stop"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "icon-only",
                icon: _ctx.remove
              }, null, 8, ["icon"])
            ]),
            _: 1
          }),
          _createElementVNode("input", {
            class: "quantity-input",
            type: "number",
            min: "0",
            max: "999",
            step: "1",
            value: _ctx.cartItem.quantity,
            onBlur: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onFinishInputCartItemQty($event)), ["stop"])),
            placeholder: "0"
          }, null, 40, _hoisted_3),
          _createVNode(_component_ion_button, {
            class: "qty-action-btn",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.incrementCartItemQty()), ["stop"]))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                slot: "icon-only",
                icon: _ctx.add
              }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        (_ctx.productQuestions.length > 0)
          ? (_openBlock(), _createBlock(_component_ion_list, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_list_header, { color: "primary" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t('CartItemModal.productQuestions')), 1)
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productQuestions, (q) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: q.id
                  }, [
                    (q.type == 'single-select' || q.type == 'multi-select')
                      ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { position: "stacked" }, {
                              default: _withCtx(() => [
                                (q.isRequired)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, "[" + _toDisplayString(_ctx.t('required')) + "] ", 1))
                                  : _createCommentVNode("", true),
                                _createTextVNode(_toDisplayString(q.label), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_ion_select, {
                              placeholder: _ctx.t('selectOption'),
                              interface: q.type == 'multi-select' ? null : 'popover',
                              multiple: q.type == 'multi-select',
                              modelValue: _ctx.cartItem.questionAnswers[q.id],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.cartItem.questionAnswers[q.id]) = $event),
                              onIonChange: _cache[4] || (_cache[4] = ($event: any) => (_ctx.cartItem.flag = !_ctx.cartItem.flag))
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(q.options, (opt) => {
                                  return (_openBlock(), _createBlock(_component_ion_select_option, {
                                    key: opt.id,
                                    value: opt
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(opt.text), 1),
                                      (opt.price > 0)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_5, " (+HK$" + _toDisplayString(opt.price) + ")", 1))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1032, ["value"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1032, ["placeholder", "interface", "multiple", "modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1024))
                      : (q.type == 'quantity-input')
                        ? (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_label, { class: "ion-text-wrap question-text" }, {
                                default: _withCtx(() => [
                                  (q.isRequired)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_6, "[" + _toDisplayString(_ctx.t('required')) + "] ", 1))
                                    : _createCommentVNode("", true),
                                  _createTextVNode(_toDisplayString(q.label), 1),
                                  (q.price > 0)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, " (+HK$" + _toDisplayString(q.price) + ")", 1))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024),
                              _withDirectives(_createElementVNode("input", {
                                class: "quantity-input",
                                type: "number",
                                "onUpdate:modelValue": ($event: any) => ((_ctx.cartItem.questionAnswers[q.id]) = $event),
                                oninput: "this.value = Math.abs(this.value)",
                                min: q.minQty || 0,
                                max: q.maxQty || '',
                                step: "1",
                                pattern: "[0-9]*",
                                placeholder: "0"
                              }, null, 8, _hoisted_8), [
                                [_vModelText, _ctx.cartItem.questionAnswers[q.id]]
                              ])
                            ]),
                            _: 2
                          }, 1024))
                        : (q.type == 'checkbox')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                              _createVNode(_component_ion_item, { lines: "full" }, {
                                default: _withCtx(() => [
                                  _withDirectives(_createElementVNode("input", {
                                    type: "checkbox",
                                    slot: "start",
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.cartItem.checkedCbxQuestions) = $event)),
                                    name: "checkedCbxQuestions",
                                    value: q
                                  }, null, 8, _hoisted_10), [
                                    [_vModelCheckbox, _ctx.cartItem.checkedCbxQuestions]
                                  ]),
                                  _createVNode(_component_ion_label, { class: "ion-text-wrap question-text" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(q.label), 1),
                                      (q.price > 0)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_11, " (+HK$" + _toDisplayString(q.price) + ")", 1))
                                        : _createCommentVNode("", true)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1024),
                              (q.cbShowTextbox && _ctx.cartItem.checkedCbxQuestions.find(o => o.id == q.id))
                                ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_textarea, {
                                        rows: "5",
                                        modelValue: q.textboxAns,
                                        "onUpdate:modelValue": ($event: any) => ((q.textboxAns) = $event),
                                        placeholder: q.cbTextboxPlaceholder,
                                        maxlength: q.cbTextboxMaxChar || ''
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "placeholder", "maxlength"])
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createVNode(_component_ion_footer, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("b", null, _toDisplayString(_ctx.t('CartPage.lineTotal')) + "HK$" + _toDisplayString(_ctx.cartItem.quantity * _ctx.getCartItemUnitPrice()), 1)
            ]),
            _createVNode(_component_ion_button, {
              expand: "block",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.addProductToCart(_ctx.product, _ctx.cartItem))),
              disabled: !_ctx.answeredAllRequiredQuestions(_ctx.productQuestions)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, {
                  slot: "start",
                  icon: _ctx.cartOutline
                }, null, 8, ["icon"]),
                _createTextVNode(" " + _toDisplayString(_ctx.isEditingCartItem ? _ctx.t('updateCart') : _ctx.t('addToCart')), 1)
              ]),
              _: 1
            }, 8, ["disabled"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}