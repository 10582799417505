import firebase from 'firebase/app';
import 'firebase/functions';

export default {
  // READ product
  async getAllProducts() {
    return (await firebase.app().functions('asia-east2').httpsCallable('product-getAllProducts')()).data;
  },
  async getProductById(id: any) {
    const res = await firebase.app().functions('asia-east2').httpsCallable('product-getProductById')({ id });
    return res.data[0];
  },
  async getAllProductCategories() {
    return (await firebase.app().functions('asia-east2').httpsCallable('product-getAllProductCategories')()).data;
  },
  async getProductsByCategoryId(id: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('product-getProductsByCategoryId')({ id })).data;
  },

  // Product Reviews
  async getProductReviews(productId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('product-getProductReviews')({ productId })).data;
  },
  async addNewProductReview(productId: any, rating: any, content: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('product-addNewProductReview')({ productId, rating, content });
  },

  // User Liked Items
  async addUserLikedItem(productId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('product-addUserLikedItem')({ productId });
  },
  async removeUserLikedItem(productId: any) {
    return await firebase.app().functions('asia-east2').httpsCallable('product-removeUserLikedItem')({ productId });
  },

  // Product Variants
  async getProductVariants(productId: any) {
    return (await firebase.app().functions('asia-east2').httpsCallable('product-getProductVariants')({ productId })).data;
  },
}